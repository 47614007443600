import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/hegel/hegel/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "function-types"
    }}>{`Function Types`}</h1>
    <hr></hr>
    <p>{`Functions have two places where you can annotate types: arguments (input) and the return value (output).`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`// Function Declaration
function sum1(a: number, b: number): number {
  return a + b;
}

// Function Expression
const sum2 = function (a: number, b: number): number {
  return a + b;
};

// Arrow Function Expression
const sum3 = (a: number, b: number): number => a + b;

let result = sum1(1, 2); // 👌!
result = sum2(1, 2); // 👌!
result = sum3(1, 2); // 👌!

// Error: Type "false" is incompatible with type "number"
result = sum1(false, "");

// Error: Type "''" is incompatible with type "number"
result = sum2("", 4);

// Error: Type "'1'" is incompatible with type "number"
result = sum3("1", "2");
`}</code></pre>
    <h2 {...{
      "id": "function-returns"
    }}>{`Function Returns`}</h2>
    <p>{`As you understand, arguments types will be checked when you try to apply arguments to a function, but return type will be checked when you implement function logic.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`function awesome(name: string): string {
  // Error: Type "2" is incompatible with type "string"
  return 2;
}
`}</code></pre>
    <p>{`Return types ensure that every `}<inlineCode parentName="p">{`return`}</inlineCode>{` will be called with the same type. This prevents you from accidentally not returning a value under certain conditions.`}</p>
    <h2 {...{
      "id": "optional-and-default-argument"
    }}>{`Optional and Default Argument`}</h2>
    <p>{`All arguments are required by default, but as was mentioned in `}<a parentName="p" {...{
        "href": "/docs/optional-types"
      }}>{`Optional Types`}</a>{`, if you annotate some argument as optional type then this argument will become optional:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`function awesome(name: ?string) {
  const actualName = name === undefined ? "you" : name;
  return \`Awesome, \${actualName}.\`;
}

let result = awesome(); // 👌!
result = awesome("JavaScript"); // 👌!
`}</code></pre>
    <p>{`In JavaScript, we can also set a default value for optional arguments like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`function awesome(name = "you") {
  return \`Awesome, \${name}.\`;
}
`}</code></pre>
    <p>{`So, which type should the `}<inlineCode parentName="p">{`name`}</inlineCode>{` argument be annotated with? You can see this demonstrated in the `}<a parentName="p" {...{
        "href": "/try#GYVwdgxgLglg9mABAQwO4FMDOcC26AUYyeAXIplAE4xgDmiAvIgEQCecIzAlIgN4BQiRJXRQQlJAAMAghmx4ANIgAkvIngC+AOkkBufhqA"
      }}>{`Hegel Playground`}</a>{`.
If you hover over the function name and then on the argument name, you will see some magic; the function argument is `}<inlineCode parentName="p">{`string | undefined`}</inlineCode>{` outside the function but `}<inlineCode parentName="p">{`string`}</inlineCode>{` inside.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`// awesome: (string | undefined) => string
function awesome(name: string = "you") {
  return \`Awesome, \${name}.\`;
}

let result = awesome(); // 👌!
result = awesome("JavaScript"); // 👌!
`}</code></pre>
    <h2 {...{
      "id": "rest-argument"
    }}>{`Rest Argument`}</h2>
    <p>{`Sometimes, you don't know how many arguments will be applied to the function. JavaScript supports rest arguments. The rest operator '...' groups all applied arguments in an array of arguments.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`function sum(...numbers) {
  // ...
}
`}</code></pre>
    <p>{`In Hegel you can annotate this argument the same as other arguments, but with a constraint. The type of this rest argument should be an `}<a parentName="p" {...{
        "href": "/docs/array-types"
      }}><inlineCode parentName="a">{`Array`}</inlineCode>{` type`}</a>{` or `}<a parentName="p" {...{
        "href": "/docs/tuple-types"
      }}>{`tuple type`}</a></p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`function sum(...numbers: Array<number>) {
  return numbers.reduce((a, b) => a + b, 0);
}

let result = sum(); // 👌!
result = sum(1); // 👌!
result = sum(1, 2); // 👌!
result = sum(1, 2, 42); // 👌!
result = sum(1, 2, 42, 14); // 👌!
// ...
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`function sum(
  ...numbers: [number] | [number, number] | [number, number, number]
) {
  return numbers.reduce((a, b) => a + b, 0);
}

// Error: Type "[]" is incompatible with type "...[number, number, number] | [number, number] | [number]"
let result = sum();
result = sum(1); // 👌!
result = sum(1, 2); // 👌!
result = sum(1, 2, 3); // 👌!

// Error Type "[1, 2, 3, 4]" is incompatible with type "...[number, number, number] | [number, number] | [number]"
result = sum(1, 2, 3, 4);
`}</code></pre>
    <h2 {...{
      "id": "function-types-1"
    }}>{`Function Types`}</h2>
    <p>{`Sometimes, you need to annotate arguments or return types as a function. Hegel has syntax for function type annotation `}<inlineCode parentName="p">{`(type1, type2) => returnType`}</inlineCode>{`. For example:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`function twice(fn: (number) => number, arg: number) {
  return fn(fn(arg));
}

let result: number = 0;

result = twice((a: number): number => a + 4, 4); // 👌!
result = twice(
  // 👌!
  (a: number | string): number => (typeof a === "string" ? parseInt(a) : a) + 4,
  4
);

// Error: Type "(string) => number" is incompatible with type "(number) => number"
result = twice((a: string): number => parseInt(a), 4);

// Error: Type "(number) => string" is incompatible with type "(number) => number"
result = twice((a: number): string => String(a), 4);

// Error: Type "(number) => number | string" is incompatible with type "(number) => number"
result = twice((a: number): number | string => a, 4);
`}</code></pre>
    <blockquote>
      <p parentName="blockquote">{`If you play with the previous example you will see the next rule of the function type:
You can assign one function to another only if the actual argument types are wider than declared and the return type is more specific than declared. This rule sounds like: function is `}<a parentName="p" {...{
          "href": "https://en.wikipedia.org/wiki/Covariance_and_contravariance_(computer_science)"
        }}>{`contravariant`}</a>{` by arguments and `}<a parentName="p" {...{
          "href": "https://en.wikipedia.org/wiki/Covariance_and_contravariance_(computer_science)"
        }}>{`covariant`}</a>{` by return.`}</p>
    </blockquote>
    <p>{`A more classical and simple example of this rule:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`class Animal {
  name: string;
  constructor(name: string) {
    this.name = name;
  }
}
class Dog extends Animal {}
class Corgi extends Dog {}

function presentMyDog(goodBoy: Dog, dogPresenter: (Dog) => Dog): string {
  const dog = dogPresenter(goodBoy);
  return \`\${dog.name}, my \${dog.name}, i will give you to noone.\`;
}

const nickolay = new Corgi("Nickolay");

let result = "";

result = presentMyDog(nickolay, (goodBoy: Dog): Dog => goodBoy); // 👌!
result = presentMyDog(nickolay, (goodBoy: Animal): Dog => nickolay); // 👌!

// Error: Type "(Corgi) => Dog" is incompatible with type "(Dog) => Dog"
result = presentMyDog(nickolay, (goodBoy: Corgi): Dog => nickolay);

result = presentMyDog(nickolay, (goodBoy: Dog): Corgi => nickolay); // 👌!

// Error: Type "(Dog) => Animal" is incompatible with type "(Dog) => Dog"
result = presentMyDog(nickolay, (goodBoy: Dog): Animal => nickolay);
`}</code></pre>
    <h2 {...{
      "id": "function-type"
    }}><inlineCode parentName="h2">{`Function`}</inlineCode>{` type`}</h2>
    <p>{`Also, Hegel supports `}<inlineCode parentName="p">{`Function`}</inlineCode>{` type which represents any possible function in JavaScript:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`function argsLength(fn: Function) {
  return fn.length;
}

let length = 0;

length = argsLength(() => 2);
length = argsLength((a: number) => a);
length = argsLength(parseFloat);

// Error: Type "2" is incompatible with type "Function"
length = argsLength(2);

// Error: Type "[]" is incompatible with type "Function"
length = argsLength([]);
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      